import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonCell, Cell, List, Section } from "@telegram-apps/telegram-ui";
import { useBackendQuery } from "@/hooks/useBackendQuery";
import { NewYearLookOrder } from "@/types";
import { LoadingComponent } from "@/components/LoadingComponent";

export const OrderDetail: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: orders, isFetching } = useBackendQuery<NewYearLookOrder[]>({
    queryKey: ["newYearLookOrders"],
    url: "/api/v1/new-year-look/orders",
  });

  const onGoToFulfillment = () => {
    navigate(`/new-year-look/admin/orders/${id}/fulfillment`);
  };

  const onGoToNotification = (isLive: boolean) => {
    navigate(
      `/new-year-look/admin/orders/${id}/notification/${isLive ? "true" : "false"}`,
    );
  };

  if (isFetching) {
    return <LoadingComponent />;
  }

  const order = orders?.find((order) => order.id === Number(id));

  if (!order) {
    return (
      <List>
        <Section header="Заказ">
          <Cell>Заказ не найден</Cell>
        </Section>
      </List>
    );
  }

  return (
    <List>
      {order && order.processedImages && (
        <Section header="Результаты">
          <ButtonCell onClick={() => onGoToNotification(false)}>
            Отправить уведомление (тест)
          </ButtonCell>
          <ButtonCell onClick={() => onGoToNotification(true)}>
            Отправить уведомление пользователю
          </ButtonCell>
          {Object.keys(order.processedImages).map((key) => (
            <Cell key={key}>
              {order.processedImages[key].map((image) => (
                <img src={image} alt="Результат" key={image} />
              ))}
            </Cell>
          ))}
        </Section>
      )}
      <Section header={`Заказ ${order?.id}`}>
        {order && (
          <>
            <ButtonCell onClick={onGoToFulfillment}>
              Загрузить результат
            </ButtonCell>
            <Cell subhead="Загруженное изображение" readOnly multiline={true}>
              {order && order.uploadedImageUrl && (
                <img
                  src={order.uploadedImageUrl}
                  alt="Фотография"
                  key={order.uploadedImageUrl}
                />
              )}
            </Cell>
            <Cell subhead="Статус">{order.status}</Cell>
            <Cell subhead="Пользователь">{order.userId}</Cell>
          </>
        )}
      </Section>
      <Section header="Объект">
        {order &&
          Object.keys(order).map((key) => (
            <Cell subhead={key} readOnly multiline={true} key={key}>
              <span className="break-all">
                {JSON.stringify(order[key as keyof NewYearLookOrder], null, 2)}
              </span>
            </Cell>
          ))}
      </Section>
    </List>
  );
};

import React from "react";
import { useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { Button, Cell, List, Section, Text, Title } from "@telegram-apps/telegram-ui";
import { useQueryClient } from "@tanstack/react-query";
import { useBackendQuery } from "@/hooks/useBackendQuery";
import { NewYearLookOrder } from "@/types";
import { LoadingComponent } from "@/components/LoadingComponent";
import { useBackendMutation } from "@/hooks/useBackendMutation";
import errorHandler from "@/util/errorHandler";
import { Icon20UploadOutline } from "@vkontakte/icons";

export const OrderFulfillment: React.FC = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data: orders, isFetching } = useBackendQuery<NewYearLookOrder[]>({
    queryKey: ["newYearLookOrders"],
    url: "/api/v1/new-year-look/orders",
  });

  const fulfill = useBackendMutation({
    mutationKey: ["newYearLookOrdersFulfillment", id],
    url: `/api/v1/new-year-look/orders/${id}`,
    method: "PUT",
    contentType: "multipart/form-data",
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["newYearLookOrders"] });
    },
    onError: errorHandler,
  });

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const formData = new FormData();
      acceptedFiles.forEach((file) => {
        formData.append("files", file);
      });
      fulfill.mutate(formData);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
    multiple: true,
  });

  if (isFetching) {
    return <LoadingComponent />;
  }

  const order = orders?.find((order) => order.id === Number(id));

  if (!order) {
    return (
      <List>
        <Section header="Заказ">
          <Cell>Заказ не найден</Cell>
        </Section>
      </List>
    );
  }

  if (fulfill.isPending) {
    return <LoadingComponent />;
  }

  return (
    <div className="p-4">
      <div className="flex flex-col gap-2">
        <Title className="mb-4">Фотографии для заказа {order?.id}</Title>
      </div>
      <div
        {...getRootProps()}
        className="mt-8 px-4 py-8 border-2 border-dashed rounded-md"
        style={{ borderColor: "var(--tg-theme-hint-color)" }}
      >
        <input {...getInputProps()} />
        <div className="flex flex-col items-center justify-center">
          {!isDragActive ? (
            <Button before={<Icon20UploadOutline />} mode="filled" size="s">
              Загрузить фотографию
            </Button>
          ) : (
            <Text>Перетащи фотографию сюда</Text>
          )}
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { Section, Cell, List, ButtonCell } from "@telegram-apps/telegram-ui";
import { Link } from "@/components/Link/Link.tsx";

export const Admin: React.FC = () => {
  const handleResetStorage = () => {
    localStorage.clear();
    alert("Локальные данные удалены");
  };

  const activateEruda = () => {
    import("eruda").then((lib) => lib.default.init());
  };

  return (
    <List>
      <Section header="Приложение">
        <Link to="/new-year-look" replace={true}>
          <Cell>Лэндинг</Cell>
        </Link>
      </Section>
      <Section header="Административные функции">
        <Link to="/new-year-look/admin/orders">
          <Cell>Заказы</Cell>
        </Link>
        <ButtonCell onClick={activateEruda}>Подключить Eruda</ButtonCell>
        <ButtonCell onClick={handleResetStorage} mode="destructive">
          Очистить локальное хранилище
        </ButtonCell>
      </Section>
      <Section header="Технические страницы">
        <Link to="/init-data">
          <Cell subtitle="User data, chat information, technical data">
            Init Data
          </Cell>
        </Link>
        <Link to="/launch-params">
          <Cell subtitle="Platform identifier, Mini Apps version, etc.">
            Launch Parameters
          </Cell>
        </Link>
        <Link to="/theme-params">
          <Cell subtitle="Telegram application palette information">
            Theme Parameters
          </Cell>
        </Link>
      </Section>
    </List>
  );
};

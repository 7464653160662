import React from "react";
import { Outlet } from "react-router-dom";

import { OrderList } from "./OrderList";
import { OrderDetail } from "./OrderDetail";
import { OrderFulfillment } from "./OrderFulfillment";
import { OrderNotification } from "./OrderNotification";

export const newYearLookAdminRoutes = [
  { path: "orders", Component: OrderList },
  { path: "orders/:id", Component: OrderDetail },
  { path: "orders/:id/fulfillment", Component: OrderFulfillment },
  { path: "orders/:id/notification/:isLive", Component: OrderNotification },
];

export const NewYearLookAdmin: React.FC = () => {
  return <Outlet />;
};

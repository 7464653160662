import React from "react";
import { useParams } from "react-router-dom";
import {
  ButtonCell,
  Cell,
  List,
  Section,
  Textarea,
} from "@telegram-apps/telegram-ui";

import { useBackendQuery } from "@/hooks/useBackendQuery";
import { NewYearLookOrder } from "@/types";
import { LoadingComponent } from "@/components/LoadingComponent";
import { useBackendMutation } from "@/hooks/useBackendMutation";
import errorHandler from "@/util/errorHandler";

export const OrderNotification: React.FC = () => {
  const { id, isLive } = useParams();
  const isLiveNotification = isLive === "true";

  const [message, setMessage] = React.useState("");

  const { data: orders, isFetching } = useBackendQuery<NewYearLookOrder[]>({
    queryKey: ["newYearLookOrders"],
    url: "/api/v1/new-year-look/orders",
  });

  const notify = useBackendMutation({
    mutationKey: ["newYearLookOrderNotification", id],
    url: `/api/v1/new-year-look/orders/${id}/send`,
    method: "POST",
    onSuccess: () => {
      alert("Уведомление отправлено");
    },
    onError: errorHandler,
  });

  const onNotify = () => {
    notify.mutate({
      isLive: isLiveNotification,
      message,
    });
  };

  if (isFetching) {
    return <LoadingComponent />;
  }

  const order = orders?.find((order) => order.id === Number(id));

  if (!order) {
    return (
      <List>
        <Section header="Заказ">
          <Cell>Заказ не найден</Cell>
        </Section>
      </List>
    );
  }

  return (
    <List>
      <Section header="Отправка уведомления">
        <Textarea
          header="Текст уведомления"
          placeholder="I am usual textarea"
          className="w-full"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        {order.processedImages && (
          <>
            {Object.keys(order.processedImages).map((key) => (
              <Cell key={key}>
                {order.processedImages[key].map((image) => (
                  <img src={image} alt="Результат" key={image} />
                ))}
              </Cell>
            ))}
          </>
        )}
        <ButtonCell onClick={onNotify}>Отправить уведомление</ButtonCell>
      </Section>
    </List>
  );
};

import React from "react";
import { Cell, List, Section } from "@telegram-apps/telegram-ui";
import { useBackendQuery } from "@/hooks/useBackendQuery";
import { NewYearLookOrder } from "@/types";
import { LoadingComponent } from "@/components/LoadingComponent";
import { useNavigate } from "react-router-dom";

export const OrderList: React.FC = () => {
  const navigate = useNavigate();

  const { data: orders, isFetching } = useBackendQuery<NewYearLookOrder[]>({
    queryKey: ["newYearLookOrders"],
    url: "/api/v1/new-year-look/orders",
  });

  const onOrderClick = (id: number) => {
    navigate(`/new-year-look/admin/orders/${id}`);
  };

  if (isFetching) {
    return <LoadingComponent />;
  }

  return (
    <List>
      <Section header="Заказы">
        {orders &&
          orders.map((order) => (
            <Cell
              subhead={`${order.userId} - ${order.status}`}
              readOnly
              multiline={true}
              key={order.id}
              onClick={() => onOrderClick(order.id)}
            >
              <span className="break-all">{order.uploadedImageUrl}</span>
            </Cell>
          ))}
      </Section>
    </List>
  );
};
